import * as React from "react";
import Layout from "../layouts";
import styled from "styled-components";

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;


  .pnf{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 129.9%;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 2rem;
    text-align: center;
    -webkit-letter-spacing: -0.005em;
    -moz-letter-spacing: -0.005em;
    -ms-letter-spacing: -0.005em;
    letter-spacing: -0.005em;
    color: var(--blue);
  }
`;

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <TextContainer>
        <h1 className="pnf">Page Not Found...</h1>
      </TextContainer>
    </Layout>
  );
};

export default NotFoundPage;
